<template>
  <div class="content-box-padding">
    <div class="sub-title">VOCs气体红外成像仪</div>
    <div class="line-text" style="margin-top: 20px">
      VOCs红外成像检漏仪主要用于石油化工行业监测甲烷等VOC气体的泄露，通过设备可快速准确定位甲烷等有机挥发气体泄漏位置为消防、环保部门快速准确定位取证、排查隐患发挥重要作用。
    </div>
    <img
      src="~@\assets\image\business\红像成像仪.png"
      alt="红像成像仪"
      class="img-center"
      style="margin: 40px 0"
    />

    <div class="sub-title">无人机搭载检测系统</div>
    <div class="line-text" style="margin-top: 20px">
      无人机搭载监测系统，可定期对区域进行全面监测溯源，有效补充空白监测区域，实现天地空一体化监测模式，同时结合无人机高空快速取证，尤其对人员难以快速到达的地方开展侦查，留存影像资料，为后期管控提供第一手资料。
      适用于环境污染突发事件应急监测、环境污染排查，企业烟囱排放落点监测、科学调研分析等。
    </div>
    <img
      src="~@\assets\image\business\无人机搭载系统.png"
      alt="红像成像仪"
      style="margin: 40px 0"
    />

    <div class="sub-title">空气微型站</div>
    <div class="line-text" style="margin-top: 20px">
      网格化微型站结合大数据融合、分析和挖掘技术，实现城市空气质量网格化全覆盖的实时监测，空气质量细粒度、高精度、实时化的空气预测，以及污染事件的污染源快速定位、污染传播路径追溯和大气污染事件的动态演变过程，并结合多维度的数据分析，统计和挖掘，为环保部门精准化管理和快速执法提供辅助决策，提高大气污染防治效率，降低污染防治所造成的无效社会成本和经济成本。
    </div>
    <img
      src="~@\assets\image\business\空气微型站.png"
      alt="空气微型站"
      style="margin: 40px 0"
    />

    <div class="sub-title">紫外差分气体分析仪</div>
    <div class="line-text" style="margin-top: 20px">
      速度可达60km/h的光化学组分走航，快速溯源分析<br />
      易操作，低维护，一台设备同时解决VOCs，NOx和O3等多参数走航及定点监测<br />
      全机身防爆设计，适用于园区泄露走航巡检<br />
    </div>
    <img
      src="~@\assets\image\business\紫外差分气体分析仪.png"
      alt="紫外差分气体分析仪"
      class="img-center"
      style="margin-top: 40px; margin-bottom: 80px; width: 80%"
    />

    <div class="sub-title">便携式气相色谱分析仪（GC-PID）</div>
    <div class="line-text" style="margin-top: 20px">
      便携式气相色谱对复杂的VOCs混合组份进行单组份分离，分离后的VOC由PID检测传感器进行检测。仪器检测后系统进行分析产生直观的浓度读数。同时该设备对数据进行深度神网络算法的动态演算与人工智能学习，从而对浓度数据与时间和地理空间坐标位置生成动态变化演算，最终形成具有准确、精准、直观和可读性强的分析报告。
    </div>
    <img
      src="~assets/image/business/色谱分析仪.png"
      alt="质子转移"
      class="img-center"
      style="margin: 55px auto"
    />

    <div class="sub-title">质子转移反应质谱仪</div>
    <div class="line-text" style="margin-top: 20px">
      国产PTR-MS：<br />
      对臭氧生成的重要前体物VOCs及恶臭主要有机成分都具有监测能力；<br />
      售后维护简易，耗材少。<br />
      应用广泛，普遍应用于环境监测等化合物分析领域。<br />
    </div>
    <img
      src="~assets/image/business/质子转移.png"
      alt="质子转移"
      class="img-center"
      style="width: 93%"
    />
  </div>
</template>

<style lang="scss" scoped>
.hardware-box {
  display: flex;
  justify-items: flex-start;
  align-items: center;
}
.haardware-box-2 {
  margin: 150px 0;
}
</style>
